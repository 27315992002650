export const reqAdmin_mealPlans_clientDetail = async(axios, { clientId }) => {
  return axios.get(`/v1/nutritionist/meal-plans/${clientId}/client-detail`)
}

export const reqAdmin_mealPlans_action = async(axios, { id, action, note }) => {
  return axios.post(`/v1/admin/meal-plans/action/${id}`, {
    note,
    status: action, // revisi | sent
  })
}

export const reqAdmin_mealPlans_getDetail = async(axios, { id }) => {
  return axios.get(`/v1/admin/meal-plans/${id}`)
}

export const reqAdmin_mealPlans_getOptionList = async(axios, { programId }) => {
  return axios.get(`/v1/admin/meal-plans/${programId}/active`)
}

export const reqAdmin_mealPlans_preview = async(axios, { clientId, programId, month }) => {
  return axios.get('/v1/admin/meal-plans/preview', {
    params: {
      clientId,
      programId,
      month,
    },
  })
}

export const reqAdmin_mealPlans_menuRecommendation_weeksDays = async(axios, { clientId, programId, month }) => {
  return axios.get(`/v1/admin/meal-plans/menu-recommendation/weeks-days?clientId=${clientId}&programId=${programId}&month=${month}`)
}

export const reqAdmin_mealPlans_menuRecommendation_formDays = async(axios, { clientId, programId, month }) => {
  return axios.get(`/v1/admin/meal-plans/menu-recommendation/form-days?clientId=${clientId}&programId=${programId}&month=${month}`)
}

export const reqAdmin_mealPlans_menuRecommendation_calendar = async(axios, { clientId, programId }) => {
  return axios.get(`/v1/admin/meal-plans/menu-recommendation/calendar?clientId=${clientId}&programId=${programId}`)
}

export const reqAdmin_mealPlans_menuRecommendation_preview = async(axios, { id }) => {
  return axios.get(`/v1/admin/meal-plans/menu-recommendation/preview/${id}`)
}

export const reqAdmin_mealPlans_downloadFilename = async(
  axios, {
    programId,
    month,
    clientId,
  }) => {
  return axios.get('/v1/admin/meal-plans/download/filename', {
    params: {
      programId,
      month,
      clientId,
    },
  })
}

export const reqAdmin_mealPlans_download = async(
  axios, {
    programId,
    month,
    clientId,
  }, {
    responseType,
  }) => {
  return axios.get('/v1/admin/meal-plans/download', {
    params: {
      programId,
      month,
      clientId,
    },
    responseType,
  })
}

export const reqAdmin_mealPlans_noteHistory = async(
  axios, {
    id,
  }) => {
  return axios.get(`/v1/admin/meal-plans/note-history/${id}`)
}

export const reqAdmin_mealPlans_specificNote = async(
  axios, {
    clientId,
  }) => {
  return axios.get(`/v1/admin/meal-plans/specific-note?clientId=${clientId}`)
}